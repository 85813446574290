@import "~normalize.css";

@import "~@blueprintjs/core/lib/css/blueprint.css";
@import "~@blueprintjs/icons/lib/css/blueprint-icons.css";
@import "~@blueprintjs/datetime/lib/css/blueprint-datetime.css";

@import "~react-grid-layout/css/styles.css";
@import "~react-resizable/css/styles.css";

p {
  margin-bottom: 0;
}

pre {
  margin: 0 !important;
}

.bp3-heading,
.bp3-form-group {
  margin-bottom: 0;
}

.bp3-label {
  margin-bottom: 6px !important;
}

.bp3-control {
  margin-bottom: 8px;
}

.bp3-form-content .bp3-control:first-child {
  margin-top: 2px;
}

.bp3-form-content .bp3-control:last-child {
  margin-bottom: 0;
}

.bp3-form-helper-text {
  margin-top: 7px !important;
}

.bp3-non-ideal-state-visual {
  margin-bottom: 16px;
}

.bp3-non-ideal-state .bp3-heading {
  margin-bottom: 8px;
}

.bp3-non-ideal-state .bp3-heading + div {
  margin-bottom: 24px;
}

.bp3-card {
  padding: 24px;
}

.bp3-dialog {
  padding-bottom: 0;
}

.bp3-dialog-header {
  /* 
    To reach equal padding for both the title and close icon, we had to change
    the title's left padding from 15px to 14px. Text has additional padding by
    itself.
  */
  padding-left: 14px;
}

.bp3-dialog-body {
  margin: 24px;
}

.bp3-dialog-footer {
  padding-bottom: 24px;
}

.react-grid-placeholder {
  border-radius: 3px;
}

.react-resizable-handle::after {
  width: 6px !important;
  height: 6px !important;
}
